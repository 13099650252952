<template>
  <div class="code-input">
    <span v-for="(index, i) in length" v-bind:key="i">
      <input type="text"
             class="form-control"
             v-mask="'#'"
             inputmode="numeric"
             ref="code"
             v-model="code[i]"
             :disabled="disabled"
             :class="{valid: hasValue(i)}"
             @paste.prevent="onPaste"
             @keydown="keydown($event, i)"
             @click.stop="selectAllByRef(i)"
      />
    </span>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  name: 'CodeInput',
  data () {
    return {
      code: []
    }
  },
  props: {
    /**
     * Inputs count
     */
    length: {
      type: Number,
      default: 6
    },

    /**
     * Make focus on first element on mounted event
     */
    autofocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    codeString: {
      get () {
        return this.code.join('')
      },
      set (val) {
        for (var i = 0; i < this.length; i++) {
          if (val[i] && /[\d]/.test(val[i])) {
            this.$set(this.code, i, val[i])
          }
        }
      }
    }
  },
  mounted() {
    if (this.autofocus) {
      this.changeFocus(0)
    }
  },
  watch: {
    'code' : debounce(function () {
      this.$emit('input', this.codeString)

      if (this.codeString.length === this.length) {
        this.$emit('fulfilled', this.codeString)
      }
    }, 100),
    'value' (newVal) {
      if (newVal === '') {
        this.code = []
        this.changeFocus(0)
      }
    }
  },
  methods: {
    onPaste (event) {
      const value = event.clipboardData.getData('text')
      if (value.length === this.length) {
        this.codeString = value
      }

      return event
    },
    keydown (event, i) {
      const n = i + 1,
            p = i - 1

      switch (event.key) {
        case 'ArrowRight':
          this.changeFocus(n)
          break;

        case 'ArrowLeft':
          this.changeFocus(p)
          break;

        case 'Backspace':
          this.$set(this.code, i, undefined)
          this.changeFocus(p)
          event.preventDefault();
          break;

        default:
          if (/[\d]/.test(event.key)) {
            this.$set(this.code, i, event.key)
            this.changeFocus(n)
            event.preventDefault()
          }
          break;
      }
    },
    hasValue (i) {
      return this.code[i] && this.code[i].length > 0
    },

    changeFocus(i) {
      if (this.$refs.code[i]) {
        this.$refs.code[i].focus()
        this.selectAllByRef(i)
      }
    },

    selectAllByRef(i) {
      if (this.$refs.code[i]) {
        let el = this.$refs.code[i]
        el.selectionStart = 0
        el.selectionEnd = el.value.length
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .code-input {
    display: flex;
    width: 100%;
    max-width: 365px;
    position: relative;
    gap: 10px;

    span {
      &:last-child {
        margin-right: 0;
      }

      .form-control {
        font-size: 24px;
        font-weight: 500;
        padding: 10px 5px;
        text-align: center;
        height: 48px;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
