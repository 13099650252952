<template>
  <div class="common-custom-checkbox">
    <input :id="id" type="checkbox" v-model="val" @change="$emit('change')">
    <label :for="id" class="common-checkbox-label" style="justify-content: left">
      <span class="custom-checkbox">
        <!--icon: {icon_name}-->
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="11" viewBox="0 0 14 11">
          <g fill="none" fill-rule="evenodd" transform="translate(-2 -4)">
            <polygon points="0 0 18 0 18 18 0 18" />
            <polygon fill="#000" fill-rule="nonzero" points="7 14.42 2 9.42 3.41 8.01 7 11.59 14.59 4 16 5.42" />
          </g>
        </svg>
      </span>
      <slot>
        <span>{{ label }}</span>
      </slot>
    </label>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId'

export default {
  name: 'Checkbox',
  props: {
    value: {
      type: [Boolean, Number],
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: uniqueId('checkbox_')
    }
  },
  computed: {
    val: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>