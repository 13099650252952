<template>
  <div id="settings-mfa" class="main-content">
    <link rel="prefetch" :href="secretImage">

    <div class="panel-wrapper">
      <div>
        <h1 class="headline">
          Two-Factor Authentication
        </h1>
        <h2 class="subline">
          We strongly recommend you to enable Two-Factor Authentication (2FA) for your {{ settings.brand_name }} account as an extra layer of security.
        </h2>
      </div>
    </div>

    <div class="inner-container">
      <div class="mfa-auth">
        <div class="mfa-auth-content" v-if="step === 1">
          <button class="default-button mfa-enable" v-if="!$store.getters.isMfaEnabled" @click="goToStep(2)">
            Enable 2FA
          </button>
          <button class="default-button default-button-disable mfa-enable" v-if="$store.getters.isMfaEnabled" @click="goToStep(2)">
            Disable 2FA
          </button>
        </div>
        <div class="mfa-auth-content" v-if="step === 2">
          <div class="mfa-action-wrp" v-if="!$store.getters.isMfaEnabled">
            <div>
              <div class="mfa-action-qr">
                <div style="padding: 10px; background: #fff; width: 220px; margin: 0 auto;">
                  <qrcode-vue :value="secretString" size="200" level="H" />
                </div>
              </div>
            </div>
            <div class="mfa-action-instructions">
              <ul class="mfa-action-list">
                <li>
                  Get the Google Authenticator app from: <br>
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Google Play</a> or
                  <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605" target="_blank">App Store</a>
                </li>
                <li>
                  Save the secret code in a safe place: <br>
                  <span class="copy-icon" @click="copy">
                    <span class="copy-icon-text">{{ secretCode }}</span>
                    <icon name="ic_copy" class="copy-icon-img"/>
                  </span>
                </li>
                <li>Open the authenticator app on your mobile device and scan this QR code or enter the secret code</li>
              </ul>
            </div>
          </div>

          <form id="mfa-form" @submit.prevent="submit">
            <form-group label="Password" :error="errors.password">
              <input @change="validate('password')" v-model="form.password" type="password" class="form-control" :class="{'valid': form.password}" autocomplete="currenct-password">
            </form-group>

            <form-group label="2FA code" :error="errors.code">
              <code-input v-model="form.code" @fulfilled="validate('code')" style="max-width: initial;"/>
            </form-group>

            <div class="flex">
              <div class="common-checkbox text-left">
                <form-group :error="errors.agree" class="common-custom-checkbox mfa-checkbox">
                  <div class="control-label" />
                  <checkbox v-model="form.agree" @change="validate('agree')">
                    <template v-if="$store.getters.isMfaEnabled">
                      <span class="custom-checkbox-text">I fully understand that by disabling 2-factor authentication, I will significantly reduce the security level</span>
                    </template>
                    <template v-else>
                      <span class="custom-checkbox-text">Yes, indeed, I have saved secret code</span>
                    </template>
                  </checkbox>
                </form-group>
              </div>
            </div>

            <div class="modal-content-bottom-panel">
              <button type="submit" class="default-button" v-if="!$store.getters.isMfaEnabled">
                Enable
              </button>
              <button type="submit" class="default-button default-button-disable" v-if="$store.getters.isMfaEnabled">
                Disable
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from '../../libs/forms/FormGroup'
import { mapState } from 'vuex'
import Icon from "@/libs/Icon";
import CodeInput from "@/libs/CodeInput";
import QrcodeVue from 'qrcode.vue'
import Checkbox from "../../libs/Checkbox";

export default {
  name: 'MultiFactorAuth',
  components: {
    Checkbox,
    CodeInput,
    Icon,
    FormGroup,
    QrcodeVue
  },
  data () {
    return {
      step: 1,

      secretCode: null,
      secretImage: null,

      form: {
        password: null,
        code: '',
        agree: null
      },

      errors: {
        password: null,
        code: null,
        agree: null
      }
    }
  },
  watch: {
    'errors': {
      handler (newVal) {
        if (newVal?.code && this.form.code.length === 6) {
          this.form.code = ''
        }
      },
      deep: true
    },
  },
  computed: {
    ...mapState({
      user: state => state.user.data,
      settings: state => state.site.settings
    }),
    formData () {
      return {
        password: this.form.password,
        agree: this.form.agree ? 1 : 0,
        code: this.form.code,
        secretCode: this.secretCode
      }
    },
    secretString () {
      return 'otpauth://totp/' + this.settings.brand_name + ':' + this.user.profile.email + '?secret=' + this.secretCode  + '&issuer=' + this.settings.brand_name
    }
  },
  mounted () {
    this.loadSecretData()
  },
  methods: {
    goToStep (n) {
      this.step = n
    },
    loadSecretData () {
      this.$sdk.settings.getMfaData().then(response => {
        this.secretCode = response.data.code
        this.secretImage = response.data.image
      })
    },
    copy () {
      this.$copyText(this.secretCode)
      this.$notice.success('The code has been copied')
    },

    /**
     * Form validating
     */
    validate: function (attribute) {
      const data = this.formData

      this.$sdk.settings.switchMfaData(data, 1).then(response => {
        this.$set(this.errors, attribute, response.data[attribute])
      })
    },

    /**
     * Form submitting
     */
    submit: function () {
      const data = this.formData

      this.$sdk.settings.switchMfaData(data, 0).then(response => {
        if (Object.keys(response.data).length > 0) {
          for (const attribute in response.data) {
            if (Object.prototype.hasOwnProperty.call(response.data, attribute)) {
              this.$set(this.errors, attribute, response.data[attribute])
            }
          }
        } else {
          this.$store.dispatch('getUser')
          this.$notice.success('MFA was updated')
          this.resetForm()
        }
      }).catch(() => {
        this.$notice.error('Something was wrong. Please reload page and try again')
      })
    },

    resetForm: function () {
      this.form.password = null
      this.form.code = ''
      this.form.agree = false

      this.goToStep(1)
    }
  }
}
</script>

<style lang="less">

  .default-button-disable {
    background-color: rgb(234, 76, 22);
    border: 1px solid rgb(234, 76, 22);
  }

  .mfa-auth {
    margin-bottom: 40px;
  }

  .mfa-action-wrp {
    display: flex;
  }
  .mfa-action-wrp > div {
    flex-flow: wrap;
  }

  .mfa-action-qr {
    text-align: center;
    border-radius: 2px;
  }

  .mfa-action-list {
    list-style: decimal;
    list-style-position: outside;
    padding-left: 20px;
  }

  .mfa-action-list li {
    padding: 15px 0 5px;
    line-height: 1.4em;
  }

  .mfa-action-list a {
    color: #FDE46A;
  }

  .mfa-action-list li:first-child {
    padding-top: 5px;
  }

  .mfa-action-wrp + #mfa-form {
    margin-top: 30px;
  }

  #mfa-form {
    max-width: 385px;

    .modal-content-bottom-panel {
      margin-top: 50px;
    }
  }
  #mfa-form .form-group {
    margin: 20px 0;

    &:first-child {
      margin-top: 0;
    }
  }

  .mfa-text {
    line-height: 1.4em;
  }
  .mfa-text-gray {
    color: rgb(117, 117, 118);
  }

  .mfa-action-instructions {
    padding-left: 30px;
    //padding-top: 15px;
    max-width: 540px;
    padding-right: 20px;
  }

  .common-checkbox .custom-checkbox-text {
    line-height: 1.4em;
  }

  .common-checkbox.text-left .common-checkbox-label {
    justify-content: left;
  }

  .copy-icon {
    cursor: pointer;
    position: relative;
    display: block;
    width: 240px;
    margin-top: 6px;

    background: rgba(255, 255, 255, .3);
    padding: 7px 40px 6px 10px;
    border-radius: 2px;

    &-text {

    }

    &-img {
      width: 24px;
      display: block;
      position: absolute;
      right: 5px;
      top: 5px;
      fill: #fff;

      path {
        fill: #FFF;
      }
    }
  }

  .mfa-checkbox {
    &.has-error .control-label {
      min-height: 14px;
    }
  }

  @media all and (max-width: 700px) {
    .mfa-action-wrp {
      flex-direction: column;
      max-width: 385px;
    }
    .mfa-action-instructions {
      padding: 0;
      margin-top: 30px;
    }
    .mfa-action-qr {
      background: rgba(255, 255, 255, .2);
      padding: 25px;
      border-radius: 2px;
    }
  }
</style>
